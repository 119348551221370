<template>
  <div id="app">
    <Topbar />
    <KeepAlive inputmode="yongxing">
      <router-view />
    </KeepAlive>

    <Footer />

  </div>
</template>

<script>
import Topbar from "./components/Topbar.vue"
import Footer from "./components/Footer.vue"
export default {
  components: {
    Topbar,
    Footer
  }
}
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}

.w {
  width: 80%;
  max-width: 1266px;
  margin: 0 auto;
}

.wrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
