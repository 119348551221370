<template>
    <div>
        <div :style="style" id="top">
            <div class="top-leth">
                <img src="../assets/img/logo.png" alt="">
            </div>
            <div class="top-right">
                <ul>
                    <li :class="{ vave: $route.path == '/home' }" @click="$router.push('/home')">首页</li>
                    <li :class="{ vave: $route.path == '/yongxin' }" @click="$router.push('/yongxin')">关于任永行</li>
                    <li :class="{ vave: $route.path == '/business' }" @click="$router.push('/business')">业务生态 </li>
                    <li :class="{ vave: $route.path == '/development' }" @click="$router.push('/development')">数字化</li>
                    <li :class="{ vave: $route.path == '/relation' }" @click="$router.push('/relation')">联系我们</li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            style: { backgroundColor: "" },
            color: false,
            scrollTop: 0,

        }
    },
    mounted() {
        window.addEventListener("scroll", this.scrollFn)
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.scrollFn)
    },
    methods: {
        scrollFn() {
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop >= 500) {
                this.style.backgroundColor = `rgb(255,255,255,${scrollTop / (scrollTop + 105)
                    }) `;
                this.color = true;
            } else if (scrollTop == 0) {
                this.style.backgroundColor = "transparent";
                this.color = false;
            }

        },
    }
}

</script>
 
<style lang = "less" scoped>
#top {
    width: 100%;
    height: 105px;
    top: 0;
    z-index: 999;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top-leth {

        img {
            width: 190px;
            height: 142px;
        }
    }

    .top-right {
        float: right;

        .vave {
            // color: #0a328e;
            color: red;
        }

        ul {
            display: flex;

            li {
                font-size: 18px;
                margin-right: 50px;
                cursor: pointer;
                // color: #fff;
                // position: relative;
            }

            .ip {
                color: #eee;
                padding-left: 50px;
                padding-right: 50px;
            }

            .yew:hover .lili {
                display: block;
            }

            .lili {
                position: absolute;
                top: 70px;
                right: 120px;
                display: none;
            }
        }

    }
}
</style>