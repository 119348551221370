<template>
    <div>
        <div class="foot">
            <div class="foot-box">
                <div class="wrap box-top">
                    <ul>
                        <li>地址：广州市天河区体育西路101号A座3203；</li>
                        <li>电话：13826626056；</li>
                    </ul>
                    <ul>

                        <li>商务合作：zr@renyongxing.com；</li>
                        <li>人力资源：gaoyi@renyongxing.com；</li>
                        <li>
                            <a
                                href="https://www.gov.cn/govweb/fuwu/bumendifangdating/bumendating/yinjianhui/index.html">合作链接</a>
                        </li>
                    </ul>

                    <ul class="ui1">
                        <li>广州任永行科技有限公司</li>
                        <li>
                            <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2023091573号-1</a>
                        </li>
                    </ul>
                </div>
                <div class="box-but">
                    <!-- <i class="iconfont icon-Android"></i> -->
                    <img src="../assets/img/footer/gongzhongh.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {


        }
    }
}
</script>
 
<style lang = "less" scoped>
.foot {
    width: 100%;
    height: 193px;
    background-color: #666;
    position: relative;

    .foot-box {
        .box-top {
            text-align: center;
            .ui1 {
                display: flex;
                justify-content: center;

                li {

                    margin-left: 10px;
                    color: #fff;
                    font-size: 15px;

                    a {
                        margin-left: 20px;
                        color: #fff;
                    }
                }

                li:nth-child(1) {
                    margin-left: 0;
                }
            }

            ul {
                display: flex;
                justify-content: center;

                li {
                    margin-top: 20px;
                    color: #fff;
                    font-size: 15px;
                    margin-left: 10px;
                    padding-top: 20px;
                }

                li:nth-child(1) {
                    margin-left: 0;
                }

                a {
                    color: #fff;
                }
            }
        }

        .box-but {
            position: absolute;
            top: 50%;
            right: 100px;
            transform: translate(0, -50%);

            img {
                width: 137px;
                height: 137px;
            }
        }
    }

}</style>