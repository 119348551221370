import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/style.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入初始化样式
import "reset-css";
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
