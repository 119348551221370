import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "home"
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/yongxin',
    name: 'Yongxin',
    component: () => import('../views/ryx_YongXin.vue')
  },
  {
    path: '/business',
    name: 'Business',
    component: () => import('../views/ryx_Business.vue')
  },
  {
    path: '/relation',
    name: 'Relation',
    component: () => import('../views/ryx_Relation.vue')
  },
  {
    path: '/serve',
    name: 'Serve',
    component: () => import('../views/ryx_Serve.vue')
  },
  {
    path: '/recycle',
    name: 'Recycle',
    component: () => import('../views/ryx_Recycle.vue')
  },
  {
    path: '/development',
    name: 'Development',
    component: () => import('../views/ryx_Development.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => { })
};


export default router
